/** @jsx jsx */
import React, { useState, useEffect, useCallback } from 'react';
import { jsx } from '@emotion/core';
import { InputErrorText, LegalStatement, Link } from './styled';
import { Checkbox, Alert, AlertVariant, magma, SpacerAxis, Spacer } from 'react-magma-dom';
import {
  ProfileEventTypes,
  useProfileUpComm
} from './screens/Profile/ProfileData';
import { InstitutionPicker } from './screens/Profile/institution/InstitutionPicker';
import { MarketOptIn } from './screens/Profile/MarketOptIn';
import { TimezoneSelect } from './screens/Profile/TimezoneSelect';
import { RequiredFieldNotice } from './basic/RequiredFieldNotice';
import { LoginInput } from './data-inputs/validated/LoginInput';
import { BirthYearInput } from './data-inputs/validated/BirthYearInput';
import { FirstNameInput } from './data-inputs/validated/FirstNameInput';
import { LastNameInput } from './data-inputs/validated/LastNameInput';
import { UserDataKeys } from './screens/Profile/UserDataKeys';
import { useTrackLoadUnload, trackingClient } from '../ext/tracking';
import { EnterAwareButton } from './magma-wrap/EnterAwareButton';
import { Marker } from './meta/Marker';
import { useStable } from './hooks/useStable';
import { FormHeader } from './basic/FormHeader';
import { DataInput } from './screens/Profile/data-input/DataInput';
import { UsernameInput } from './data-inputs/validated/UsernameInput';

export const TEST_ID = {
  MARKER: 'marker-student-create-profile',
  BUTTON_SUBMIT: 'submit-button',
  ERR_TEST_ID: 'error-alert'
};

export interface CreateProfileTestProps {
  skipLoadDiscover?: boolean;
}

export interface CreateProfileProps {
  username?: string;
  contextId?: string;
  __test?: CreateProfileTestProps;
  registerErrors?: any;
}

export const CreateProfile: React.FunctionComponent<CreateProfileProps> = (
  props: CreateProfileProps
) => {
  const { username, contextId, registerErrors } = props;

  const upComm = useProfileUpComm();

  const [error, setError] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);


  const [hideTermsAndConditions, setHideTermsAndConditions] = useState(false);
  const [hideMarketingOptIn, setHideMarketingOptIn] = useState(false);


  const [institutionName, setInstitutionName]= useState(undefined);


  const trackCategory = trackingClient.event.registrationDetails;
  useTrackLoadUnload(trackCategory);
  
  const loginIdRegisterError = registerErrors ? registerErrors[UserDataKeys.login] : null;

  const handleSubmit = () => {
    upComm.sendMsg({
      type: ProfileEventTypes.dataSaved,
      data: {
        onError: (err: Error) => {
          if (err) {
            setError(err.message);
          }
        }
      }
    });
  };

  const updateAgreement = (event: any) => {
    const { target } = event;
    setAgreeTerms(target.checked);
  };

  const isSubmitDisabled = useCallback(() => {
    return !(agreeTerms && upComm?.ctx.isValid);
  }, [agreeTerms, upComm]);

  useEffect(() => {
    if (contextId) {
      const uxModeFromContext = window.backend.parsedUXmode;
      const acceptTermsAndConditions = uxModeFromContext.termsAndConditions && !!uxModeFromContext.termsAndConditions.hidden;
      setInstitutionName(uxModeFromContext.institutionName && uxModeFromContext.institutionName.value);
      setHideMarketingOptIn(uxModeFromContext.marketingOptIn && !!uxModeFromContext.marketingOptIn.hidden);
      setHideTermsAndConditions(acceptTermsAndConditions);
      setAgreeTerms(acceptTermsAndConditions);
      if (upComm) {
        upComm.sendMsg({
          type: ProfileEventTypes.dataInit,
          data: {
            key: UserDataKeys.contextId,
            value: contextId
          }
        });
      }
    }
    
    if (username && upComm && !loginIdRegisterError) {
      upComm.sendMsg({
        type: ProfileEventTypes.dataInit,
        data: {
          key: UserDataKeys.login,
          value: username
        }
      });
    }
  }, [loginIdRegisterError, contextId, upComm, username]);

  // Discover the user on page load, once the rendering loop has stabilized
  useStable({
    cb: () => {
      if (username && upComm && !props.__test?.skipLoadDiscover) {
        upComm.sendMsg({ type: ProfileEventTypes.discoverUsername });
      }
    }
  });

  return (
    <React.Fragment>
      <Marker testId={TEST_ID.MARKER}/>

      <FormHeader>Create Account</FormHeader>
      <RequiredFieldNotice/>

      {error && (
        <Alert variant={AlertVariant.warning} testId={TEST_ID.ERR_TEST_ID}>
          <InputErrorText>{error}</InputErrorText>
        </Alert>
      )}

      {!upComm.ctx.overrides?.values[UserDataKeys.skipEmailVerification] && 
        <LoginInput
          upComm={upComm}
          defaultValue={username}
          readonly={!!username && !loginIdRegisterError}
          onErrorBlur={e =>
            trackCategory.validationError({
              tags: { error: e, name: UserDataKeys.login }
            })
          }
          autoFocus
          registerErrorMessage={loginIdRegisterError}
        />
      }
      {upComm.ctx.overrides?.values[UserDataKeys.skipEmailVerification] &&
        <UsernameInput
          upComm={upComm}
          defaultValue={username}
          readonly={!!username && !loginIdRegisterError}
          onErrorBlur={e =>
            trackCategory.validationError({
              tags: { error: e, name: UserDataKeys.login }
            })
          }
          autoFocus
          registerErrorMessage={loginIdRegisterError}
        />
      }
      {contextId && institutionName && < DataInput
        readonly={true}
        dataLabel={`Institution`}
        value={institutionName}
        {...props}
      />
      }
      <FirstNameInput
        upComm={upComm}
        onErrorBlur={e =>
          trackCategory.validationError({
            tags: { error: e, name: UserDataKeys.firstName }
          })
        }
      />
      <LastNameInput
        upComm={upComm}
        onErrorBlur={e =>
          trackCategory.validationError({
            tags: { error: e, name: UserDataKeys.lastName }
          })
        }
      />
      <BirthYearInput
        upComm={upComm}
        onErrorBlur={e =>
          trackCategory.validationError({
            tags: { error: e, name: UserDataKeys.birthYear }
          })
        }
      />
      <InstitutionPicker
        required={true}
        upComm={upComm}
        onFocus={() => {
          trackCategory.institutionSelectionStart();
        }}
      />
      <TimezoneSelect
        required={true}
        upComm={upComm}
        onFocus={() => {
          trackCategory.timezoneSelectionStart();
        }}
      />
      {!hideMarketingOptIn && <MarketOptIn upComm={upComm} skipDataInit={false}/>}

      {!hideTermsAndConditions && <Checkbox
        labelText="I agree to the Terms of Use and Privacy Policy"
        labelStyle={{ textAlign: 'left' }}
        onChange={updateAgreement}
        testId="register-check-agree-terms"
        id="register-check-agree-terms"
        checked={agreeTerms}
      />
      }

      <EnterAwareButton
        style={{ minWidth: '300px' }}
        isFullWidth={true}
        value="Next"
        disabled={isSubmitDisabled()}
        onClick={() => {
          trackingClient.event.registrationDetails.nextButtonClick();
          handleSubmit();
        }}
        testId={TEST_ID.BUTTON_SUBMIT}
        id="submit-button"
      />
      <LegalStatement>
        <Link
          href="https://www.cengage.com/legal/#students"
          testId="terms-of-service"
          target="_blank"
          onClick={() => {
            trackingClient.event.registrationDetails.termsOfUseClick();
          }}
        >
          Terms of Use
        </Link>
        <Spacer axis={SpacerAxis.horizontal} size={magma.spaceScale.spacing07}/>
        <Link
          href="https://www.cengage.com/privacy/"
          testId="privacy-policy"
          target="_blank"
          onClick={() => {
            trackingClient.event.registrationDetails.privacyPolicyClick();
          }}
        >
          Privacy Policy
        </Link>
      </LegalStatement>
    </React.Fragment>
  );
};
