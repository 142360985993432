/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import React from 'react';
import {FooterLink} from '../styled';

export interface ProfileFooterProps {
  termsOfServiceAudience: string;
}

export const ProfileFooter: React.FC<ProfileFooterProps> = props => {
  const termsOfServiceUrl = `https://www.cengage.com/legal/${props.termsOfServiceAudience}`;
  return (
    <footer>
      <ul css={css`
    text-align: center;
    padding-inline-start: 0;
    `}>
        <FooterLink>
          <a href="https://www.cengage.com/about/"
            target="_blank"
            data-testid="footer-about-us-link"
            rel="noreferrer">
            About Us
          </a>
        </FooterLink>
        <FooterLink>
          <a href={window.backend.cengageGenericHelpUrl}
            target="_blank"
            data-testid="footer-help-link"
            rel="noreferrer">
            Help
          </a>
        </FooterLink>
        <FooterLink>
          <a href="https://www.cengage.com/accessibility/"
            target="_blank"
            data-testid="footer-accessibility-link"
            rel="noreferrer">
            Accessibility
          </a>
        </FooterLink>
        <FooterLink>
          <a href={termsOfServiceUrl}
            target="_blank"
            data-testid="footer-terms-of-service-link"
            rel="noreferrer">
            Terms of Service
          </a>
        </FooterLink>
        <FooterLink>
          <a href="https://www.cengage.com/privacy/"
            target="_blank"
            data-testid="footer-privacy-link"
            rel="noreferrer">
            Privacy Statement
          </a>
        </FooterLink>
        <FooterLink>
          <a href="https://www.cengage.com/piracy/"
            target="_blank"
            data-testid="footer-report-piracy-link"
            rel="noreferrer">
            Report Piracy
          </a>
        </FooterLink>
        <FooterLink>
          © {new Date().getFullYear()} Cengage
        </FooterLink>
      </ul>
    </footer>
  );
};